import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/styles';
import 'react-toastify/dist/ReactToastify.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Loader from 'components/Loader';
import MainSideBar from 'components/MainSidebar';
import DetailsBroker from 'features/Borker/DetailsBroker';
import ErrorBoundary from 'features/Errors/ErrorBoundary';
import { magikActions } from 'features/Magik/magik.slice';
import DetailsVender from 'features/Vender/DetailsVender';
import { initializeApp } from 'firebase/app';
import { ToastContainer } from 'react-toastify';
import { clientActions } from 'store/clientSlice';
import { stClientActions } from 'store/stClientSlice';
import { SCREEN_BG_COLOR } from 'utils/enums';
import Protected from 'utils/ProtectedRoutes';

const Login = React.lazy(() => import('features/Login'));
const Clients = React.lazy(() => import('features/Clients'));
const StClients = React.lazy(() => import('features/ST/STClients'));
const Leads = React.lazy(() => import('features/Leads'));
const FillNonTransOrderDetails = React.lazy(() =>
  import('features/Orders/FillNonTransOrderDetails')
);
const OrderDetails = React.lazy(() => import('features/Orders/OrderDetails'));
const AddOrder = React.lazy(() => import('features/Orders/AddOrder'));
const Callbacks = React.lazy(() => import('features/Callbacks'));
const AddCallback = React.lazy(() => import('features/Callbacks/AddCallback'));
const CallBackDetails = React.lazy(() => import('features/Callbacks/CallBackDetails'));
const IAAI = React.lazy(() => import('features/IA-AI/IA-AI'));
const Properties = React.lazy(() => import('features/Properties'));
const Redbox = React.lazy(() => import('features/Redbox'));
const SearchNotFound = React.lazy(() => import('features/Errors/SearchProblem'));
const NotFound = React.lazy(() => import('features/Errors/NotFound'));
const Cart = React.lazy(() => import('features/Cart'));
const Search = React.lazy(() => import('features/Search'));
const ClientComments = React.lazy(() => import('features/Orders/ClientComments'));
const ClientDetails = React.lazy(() => import('features/Clients/ClientDetails'));
const AddClient = React.lazy(() => import('features/Clients/AddClient'));
const PropertyDetails = React.lazy(() => import('features/Properties/PropertyDetails'));
const AddProperty = React.lazy(() => import('features/Properties/AddProperty'));
const AddLead = React.lazy(() => import('features/Leads/AddLead'));
const Events = React.lazy(() => import('features/Events'));
const CreateEvent = React.lazy(() => import('features/Events/CreateEvent'));
const EventDetails = React.lazy(() => import('features/Events/EventDetails'));
const Magik = React.lazy(() => import('features/Magik'));
const MagikListing = React.lazy(() => import('features/Magik/MagikListing'));
const ProspectiveBuyersListing = React.lazy(() =>
  import('features/Magik/ProspectiveBuyersListing')
);
const AddBuyer = React.lazy(() => import('features/Magik/AddBuyer'));
const ChannelPartnerDetails = React.lazy(() => import('features/Magik/ChannelPartnerDetails'));
const Reports = React.lazy(() => import('features/Events/Reports'));
const TransactionalOrders = React.lazy(() => import('features/Orders/TransactionalOrders'));
const NonTransactionalOrders = React.lazy(() => import('features/Orders/NonTransactionalOrders'));
const NonTransactionalOrderDetails = React.lazy(() =>
  import('features/Orders/NonTransactionalOrderDetails')
);
const STYardDetails = React.lazy(() => import('features/ST/STYardDetails'));
const TransactionalOrderDetails = React.lazy(() =>
  import('features/Orders/TransactionalOrderDetails')
);
const AddNewOrder = React.lazy(() => import('features/Orders/AddOrder'));
const FillTransOrderDetails = React.lazy(() =>
  import('features/Orders/components/FillTransOrderDetails')
);
const Dashboard = React.lazy(() => import('features/Dashboard'));
const TotalClientsAndProperties = React.lazy(() =>
  import('features/Dashboard/TotalClientsAndProperties')
);
const TotalAUM = React.lazy(() => import('features/Dashboard/TotalAUM'));
const PaymentReceived = React.lazy(() => import('features/Dashboard/components/PaymentReceived'));
const ChannelPartnerListing = React.lazy(() => import('features/Magik/ChannelPartnerListing'));
const EmiCalculator = React.lazy(() => import('features/Magik/EmiCalculator'));
const Builders = React.lazy(() => import('features/PrimarySales/Builders'));
const AddBuilder = React.lazy(() => import('features/PrimarySales/Builders/AddBuilder'));
const BuilderDetails = React.lazy(() => import('features/PrimarySales/Builders/BuilderDetails'));
const PrimarySalesProperties = React.lazy(() => import('features/PrimarySales/Properties'));
const PrimarySalesTransOrders = React.lazy(() =>
  import('features/PrimarySales/TransactionalOrders')
);
const PrimarySalesPropertyDetails = React.lazy(() =>
  import('features/PrimarySales/Properties/PropertyDetails')
);
const PrimarySalesTransOrderDetails = React.lazy(() =>
  import('features/PrimarySales/TransactionalOrders/TransactionalOrderDetails')
);
const AddBroker = React.lazy(() => import('features/Borker/AddBroker'));
const Broker = React.lazy(() => import('features/Borker'));
const Loans = React.lazy(() => import('features/Loans'));
const LoanDetails = React.lazy(() => import('features/Loans/LoanDetails'));
const AddLoan = React.lazy(() => import('features/Loans/AddLoan'));
const LeadDetails = React.lazy(() => import('features/Leads/LeadDetails'));
const LoanLeads = React.lazy(() => import('features/Loans/LoanLeads'));
const RevenueBooked = React.lazy(() => import('features/Dashboard/RevenueBooked'));
const AddVender = React.lazy(() => import('features/Vender/AddVender'));
const Vender = React.lazy(() => import('features/Vender'));
const Vista = React.lazy(() => import('features/Orders/Vista'));
const TaxAssessment = React.lazy(() => import('features/Orders/TaxAssessment'));

const AddPayment = React.lazy(() => import('features/Orders/components/Payments/AddPayment'));
const PaymentDetails = React.lazy(() =>
  import('features/Orders/components/Payments/PaymentDetails')
);
const PerformancePointDetails = React.lazy(() =>
  import('features/Orders/components/Payments/PerformancePointDetails')
);
const STYard = React.lazy(() => import('features/ST/STYard'));

const firebaseConfig = {
  apiKey: 'AIzaSyCpDhsP4dDOobD1GjEfxmY1ZJYzDhVCIas',
  appId: '1:764709408876:web:065b48e21a4bf423f20849',
  authDomain: 'doors-20691.firebaseapp.com',
  measurementId: 'G-BQDN38T2MX',
  messagingSenderId: '764709408876',
  projectId: 'doors-20691',
  storageBucket: 'doors-20691.appspot.com'
};

initializeApp(firebaseConfig);

export default function App() {
  const theme = createTheme({
    typography: {
      fontFamily: 'Lato'
    }
  });

  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.auth?.role);

  const isLoggedIn = useSelector((state) => state.auth?.isLoggedIn);
  const [routesToHideSideNavBar] = useState(['/login', '/search-not-found']);
  const [isError, setIsError] = useState(false);

  const handleSetError = (err) => setIsError(err);

  useEffect(() => {
    if (!pathname?.startsWith('/client-details') && !pathname?.startsWith('/clients')) {
      dispatch(clientActions.setCurrentTablePage(0));
    }

    if (!pathname?.startsWith('/st/client-details') && !pathname?.startsWith('/st/clients')) {
      dispatch(stClientActions.setCurrentTablePage(0));
    }

    if (
      !pathname?.startsWith('/prospective-buyers') &&
      !pathname?.startsWith('/order/transactional-details/')
    ) {
      dispatch(magikActions.setCurrentTablePage(0));
    }
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor:
            (pathname.includes('/dashboard') ||
              pathname.includes('payment') ||
              pathname.includes('broker') ||
              pathname.includes('vendor') ||
              pathname.includes('performance-point-details') ||
              pathname.includes('add-performance-point') ||
              pathname.includes('/edit-performance-poin')) &&
            !pathname.startsWith('/orders')
              ? '#fff'
              : SCREEN_BG_COLOR
        }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {!routesToHideSideNavBar.includes(window.location.pathname) && isLoggedIn && !isError && (
            <MainSideBar />
          )}
          <Box sx={{ minHeight: '100vh', width: '100%' }}>
            <ErrorBoundary handleSetError={handleSetError}>
              <Routes>
                <Route
                  path="/"
                  element={isLoggedIn ? <Navigate to="/dashboard" /> : <Navigate to="/login" />}
                />
                <Route
                  path={'/login'}
                  element={
                    <Suspense fallback={<Loader />}>
                      {isLoggedIn ? <Navigate to="/dashboard" /> : <Login />}
                    </Suspense>
                  }
                />
                <Route
                  path="/search-not-found"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <SearchNotFound />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/search"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <Search />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/clients"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <Clients />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/st/clients"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <StClients />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/edit-client/:clientId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddClient />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/st/edit-client/:clientId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddClient />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/loan/leads"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <LoanLeads />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/loan/edit-lead-details/:leadId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddLead />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/loan/lead-details/:leadId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <LeadDetails />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/add-vendor"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddVender />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/vendor-edit/:vendorId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddVender />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/vendors"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <Vender />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/vendor-details/:vendorId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <DetailsVender />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/loan/add-lead"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddLead />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/leads"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <Leads />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/edit-lead-details/:leadId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddLead />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/lead-details/:leadId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <LeadDetails />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/add-lead"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddLead />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/properties/:clientId?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <Properties />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/st/properties/:clientId?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <Properties />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/orders/transactional"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <TransactionalOrders />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/orders/vista"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <Vista />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/orders/tax-assessment"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <TaxAssessment />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/orders/:productName/:orderId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <OrderDetails />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/st/cases"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <TransactionalOrders />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/orders/non-transactional"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <NonTransactionalOrders />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/redbox"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <Redbox />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/callbacks"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <Callbacks />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/magik/ia-ai"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <IAAI />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/add-callback/:clientId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddCallback />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/callback-details/:clientId/:callbackId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <CallBackDetails />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/add-order/:clientId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddOrder />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/add-order/:clientId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddOrder />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/property-details/:clientId/:propId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <PropertyDetails />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/st/property-details/:clientId/:propId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <PropertyDetails />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/edit-property/:clientId/:propId?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddProperty />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/st/edit-property/:clientId/:propId?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddProperty />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/primary-sales/edit-property/:clientId/:propId?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddProperty />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/cart/:clientId/:propertyId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <Cart />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/st/cart/:clientId/:propertyId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <Cart />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/:transType/:orderId/client-comments"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <ClientComments />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/st/:transType/:orderId/client-comments"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <ClientComments />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/client-details/:clientId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <ClientDetails />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/st/client-details/:clientId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <ClientDetails />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/add-client"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddClient />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/st/add-client"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddClient />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/magik"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <Magik />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/magik-listing"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <MagikListing />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/prospective-buyers"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <ProspectiveBuyersListing />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/primary-sales/prospective-buyers"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <ProspectiveBuyersListing />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/add-property/:clientId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddProperty />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/st/add-property/:clientId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddProperty />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/primary-sales/add-property/:clientId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddProperty />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/events"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <Events />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/event/:eventId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <EventDetails />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/create-event"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <CreateEvent />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/update-event/:eventId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <CreateEvent />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/add-buyer/:orderId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddBuyer />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/st/add-buyer/:orderId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddBuyer />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/primary-sales/add-buyer/:orderId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddBuyer />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/events-report"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <Reports />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/order/non-transactional-details/:orderId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <NonTransactionalOrderDetails />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/st/yard/:orderId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <STYardDetails />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/order/transactional-details/:orderId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <TransactionalOrderDetails />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/st/order/transactional-details/:orderId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <TransactionalOrderDetails />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/add-order/:clientId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddNewOrder />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/st/add-order/:clientId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddNewOrder />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/add-broker"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddBroker />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/brokers"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <Broker />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/broker/:brokerId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <DetailsBroker />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/broker-edit/:brokerId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddBroker />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/primary-sales/add-order/:clientId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddNewOrder />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/add-order/Transactional/:selectedCategory/:clientId/:propertyId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <FillTransOrderDetails />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/st/add-order/Transactional/:selectedCategory/:clientId/:propertyId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <FillTransOrderDetails />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/primary-sales/add-order/Transactional/:selectedCategory/:clientId/:propertyId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <FillTransOrderDetails />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/add-order/Non-Transactional/:selectedCategory/:clientId/:propertyId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <FillNonTransOrderDetails />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/st/add-order/Non-Transactional/:selectedCategory/:clientId/:propertyId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <FillNonTransOrderDetails />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/dashboard"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <Dashboard />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/dashboard/total-clients"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <TotalClientsAndProperties />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/dashboard/total-properties"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <TotalClientsAndProperties />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/dashboard/total-aum"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <TotalAUM />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/dashboard/payment-received"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <PaymentReceived />
                      </Protected>
                    </Suspense>
                  }
                />

                {['Super Admin', 'Admin'].includes(userRole) && (
                  <Route
                    path="/magik/channel-partners"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Protected>
                          <ChannelPartnerListing />
                        </Protected>
                      </Suspense>
                    }
                  />
                )}

                <Route
                  path="/magik/channel-partner-details/:channelPartnerId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <ChannelPartnerDetails />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/magik/emi-calculator"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <EmiCalculator />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/primary-sales/builders"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <Builders />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/primary-sales/add-builder"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddBuilder />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/primary-sales/builder/:builderId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <BuilderDetails />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/primary-sales/edit-builder/:builderId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddBuilder />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/primary-sales/properties"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <PrimarySalesProperties />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/primary-sales/property/:clientId/:propertyId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <PrimarySalesPropertyDetails />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/primary-sales/transactional-orders"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <PrimarySalesTransOrders />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/primary-sales/transactional-order/:orderId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <PrimarySalesTransOrderDetails />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/loans"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <Loans />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/loan-details/:clientId/:loanId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <LoanDetails />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/add-loan/:clientId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddLoan />
                      </Protected>
                    </Suspense>
                  }
                />
                <Route
                  path="/edit-loan/:clientId/:loanId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddLoan />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/dashboard/revenue-booked"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <RevenueBooked />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/:transType/add-payment-received-from-client?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddPayment />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/:transType/st/add-payment-received-from-client?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddPayment />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/:transType/add-final-payment?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddPayment />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/:transType/st/add-final-payment?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddPayment />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/:transType/add-token-advance-payment?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddPayment />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/:transType/add-partial-revenue-payment?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddPayment />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/:transType/add-total-revenue-payment?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddPayment />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/:transType/add-payment-tax-assessment?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddPayment />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/edit-payment-transactional?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddPayment />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/edit-payment-non-transactional?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddPayment />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/st/edit-payment?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddPayment />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/edit-payment-tax-assessment?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddPayment />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/edit-performance-point-transactional?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddPayment />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/st/edit-performance-point?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddPayment />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/edit-performance-point-non-transactional?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddPayment />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/primary-sales/edit-performance-point?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddPayment />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="primary-sales/edit-payment?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddPayment />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/:transType/add-performance-point?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddPayment />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/:transType/st/add-performance-point?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddPayment />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/:transType/payment-details?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <PaymentDetails />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/tax-assessment/payment-details?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <PaymentDetails />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/:transType/st/payment-details?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <PaymentDetails />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/:transType/performance-point-details?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <PerformancePointDetails />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/:transType/st/performance-point-details?"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <PerformancePointDetails />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/st/yard"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <STYard />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route path="*" element={<NotFound />} />
              </Routes>
            </ErrorBoundary>
            <ToastContainer autoClose={2000} hideProgressBar={false} closeOnClick />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
